import React from "react";
import Slider from "react-slick";

const NftSlider: React.FC<any> = ({ numberOfImages }) => {
  const settings = {
    autoplay: true,
    infinite: true,
    speed: 2000,
    slidesToShow: numberOfImages,
    slidesToScroll: 1,
    pauseOnHover: false,
  };

  return (
    <div className="w-full">
      <Slider {...settings}>
        {Array.from({ length: 9 }, (_, index) => (
          <img
            key={index}
            src={`/assets/home/nft/${(index % 9) + 1}.png`}
            className='w-[250px]! h-[250px]! flex-shrink-0'
          />
        ))}
      </Slider>
    </div>
  );
};

export default NftSlider;
