import "./index.css";
import Header from '../../component/header';
import MainSection from './mainSection';
import Slidebar from '../../component/slideBar';
import SecondSection from './secondSection';
import ComposeSection from './composeSection';
import ThirdSection from './thirdSection';
import SlothBallSection from './slothBallSection';
import FaqSection from './faqSection';
import MintDialog from './MintDialog';
import MobileMenu from "../../component/MobileMenu";

const Home = () => {

  return (
    <div className="relative w-screen parallax bg-gray-1" id="cred-point">
      <Header />
      <MainSection />
      <Slidebar />
      <SecondSection />
      <ComposeSection />
      <Slidebar withBorder={true} />
      <ThirdSection />
      <SlothBallSection />
      <FaqSection />
      <MintDialog />
      <MobileMenu />
    </div>
  );
};

export default Home;
