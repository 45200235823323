import React from "react";
import SecondaryButton from "./secondaryButton";

const DownloadSection: React.FC<any> = () => {
  return (
    <div className="flex flex-col gap-y-6">
      <SecondaryButton className={'w-[300px] h-12 mt-[72px] text-lg'}>DAPP COMING SOON</SecondaryButton>
      <div className="flex items-center justify-center gap-x-3">
        <img src="/assets/home/download-google-play.png" className="h-11" alt="google-play" />
        <img src="/assets/home/download-app-store.png" className="h-11" alt="app-store" />
      </div>
    </div>
  )
}

export default DownloadSection;
