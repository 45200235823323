import React from 'react';

const SecondaryButton: React.FC<any> = ({ children, className }) => {
  return (
    <button className={`${className} flex items-center font-[900] justify-center rounded-[10px] bg-secondary border border-gray-dark text-[white] shadow-default`}>
      {children}
    </button>
  );
};

export default SecondaryButton;
