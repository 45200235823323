import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import LinkText from "../../component/linkText";
import JoinUs from '../../component/header/joinus';

const FaqSection: React.FC<any> = () => {
  const navigate = useNavigate();

  const [openSection, setOpenSection] = useState<number | null>(null);

  const faqs = [
    {
      question: 'What is the collection supply?',
      answer: () => (
        <>
          <span>Hello explorer! In the magical world of Cool Sloths, we have <span className="font-bold">7,000</span> special hidden SlothBalls. Just like stars in the sky, each one is unique and waiting for you to find it. Get a one-of-a-kind SlothBall, ready to evolve into a Cool Sloth with you!</span>
        </>
      ),
    },
    {
      question: 'How to mint SlothBall?',
      answer: () => (
        <>
          <span>1. First, get the <LinkText><a href="https://www.townesquare.xyz/" target="_blank">TowneSquare app</a></LinkText>.</span>
          <span>2. Find a friend who uses Aptos and ask them for a <LinkText><a href="https://cred.townesquare.xyz/" target="_blank">CRED</a></LinkText> referral code. If you already use CRED, you can use the same wallet.</span>
          <span>3. Sign up & look for in-app instructions on how to mint your SlothBall.</span>
        </>
      ),
    },
    {
      question: 'How to Evolve my SlothBall into a Cool Sloths?',
      answer: () => (
        <>
          <span>1. Turn on notifications in the <LinkText><a href="https://www.townesquare.xyz/" target="_blank">TowneSquare app</a></LinkText> and wait for the call to action to show up in your Feed.</span>
          <span>2. Join the Slothball Evolving event and own at least one Slothball.</span>
          <span>3. Fight and defeat the Evil Sloth together with other Slothball holders!</span>
          <span>4. Evolve your SlothBall into the Cool Sloth.</span>
        </>
      ),
    },
    {
      question: 'Join the Token-Gated Cool Sloths community',
      answer: () => (
        <>
          <span>Cool Sloths community will come exclusively on the <LinkText><a href="https://www.townesquare.xyz/" target="_blank">TowneSquare app</a></LinkText> after mint. Only Cool Sloths holders will be able to join to access exclusive content, perks, and opportunities inside the holder community.</span>
        </>
      ),
    },
    {
      question: 'What are the benefits?',
      answer: () => (
        <>
          <span>Cool Sloths is the official collection of TowneSquare & CRED. Holders will enjoy the ultimate perks, drops, content, & opportunities of major ecosystem milestones.</span>
        </>
      ),
    },
  ];

  const toggleSection = (index: number) => {
    if (openSection === index) {
      setOpenSection(null);
    } else {
      setOpenSection(index);
    }
  };

  return (
    <div className='relative flex flex-col items-center w-full text-gray-dark'>
      <img
        src="/assets/home/bg-footer.png"
        className="w-full h-[240px] absolute bottom-0"
      />
      <p className='text-4xl md:text-[50px] leading-none font-extrabold mt-[110px]'>FAQ</p>
      <div className="w-[95%] lg:w-[835px] flex flex-col mt-14 z-10 gap-4">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`px-6 border border-black rounded-[10px] shadow-default
              ${openSection === index ? 'bg-primary' : 'bg-white'}`}
          >
            {/* Question */}
            <button
              className="flex items-center justify-between w-full py-8 font-black gap-x-4 md:gap-x-0"
              onClick={() => toggleSection(index)}
            >
              <p className='text-[17px] sm:text-[26px] text-start'>{faq.question}</p>
              <Icon
                className={`transition-all ease-linear duration-300 transform
                  ${index === openSection ? 'rotate-180' : 'rotate-0'}`}
                icon={'bytesize:chevron-bottom'}
                fontSize={24}
                color='black'
              />
            </button>
            {/* Answer */}
            <div
              className={`overflow-hidden transition-max-height duration-500 ease-in-out
                ${openSection === index ? 'max-h-fit pb-8' : 'max-h-0'}`}
            >
              <div className="flex flex-col text-base font-medium sm:pb-0 gap-y-8 sm:text-lg">{faq.answer()}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="z-10 flex flex-col w-full px-10 pb-12 mt-24 md:mt-44 md:px-20 gap-y-12">
        <div className="flex items-center justify-between w-full bg-primary">
          <img
            onClick={() => navigate("/")}
            src="/assets/logo.svg"
            className="w-auto h-16 cursor-pointer md:h-12"
          />
          <span className="hidden text-lg font-medium md:block">
            Copyright © 2024. All rights reserved.
          </span>
          <div>
            <JoinUs />
          </div>
        </div>
        <span className="text-xs font-medium md:hidden">
          Copyright © 2024. All rights reserved.
        </span>
      </div>
    </div>
  );
};

export default FaqSection;
