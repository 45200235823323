const ThirdSection: React.FC<any> = () => {
  const goToCred = () => {
    window.open('https://cred.townesquare.xyz/', '_blank');
  };

  return (
    <>
      <div className='flex flex-col w-full relative bg-gray-dark h-[552px] py-[65px]' style={{ backgroundImage: 'url("/assets/home/socrates.svg")', backgroundRepeat: 'no-repeat', backgroundPosition: 'top left' }}>
        <img className='w-16 h-16 md:w-[120px] md:h-[120px] absolute top-8 left-10 md:top-20 md:left-[260px]' src='/assets/home/credCoin2.svg' />
        <img className='w-16 h-16 md:w-[120px] md:h-[120px] absolute bottom-3 right-2 md:bottom-10 md:right-[400px]' src='/assets/home/credCoin1.svg' />
        <div className='flex flex-col w-full items-center z-[20]'>
          <img className='w-[141px] h-[60px]' src='/assets/logo-cred.svg' />
          <p className='text-5xl md:text-[66px] font-bold mt-10 leading-[100%]'>Cool Sloths</p>
          <p className='text-3xl md:text-[42px] font-bold leading-[100%] mt-3'>earn you 500 $CRED/day</p>
          <button
            className={`flex w-[142px] mt-[68px] font-[900] h-[40px] items-center justify-center rounded-[10px] bg-[white] border border-gray-dark text-[black] shadow-default`}
            onClick={goToCred}
          >
            JOIN CRED
          </button>
          <p className='text-[14px] opacity-[50%] font-semibold leading-[100%] mt-[37px]'>Already a user?</p>
          <a
            className='text-[18px] underline font-semibold leading-[100%] mt-[29px]'
            href="https://cred.townesquare.xyz/"
            target="_blank"
          >Check your CRED ranking here</a>
        </div>
      </div>
    </>
  );
};

export default ThirdSection;
