import React from "react";
import { useNavigate } from "react-router-dom";

interface LinkTextProps {
  text?: string;
  classes?: string;
  link?: string;
  func?: () => void;
  children?: React.ReactNode;
}

const LinkText: React.FC<any> = ({ text, classes, link, func, children }: LinkTextProps) => {
  const navigate = useNavigate();

  const event = {
    onClick: () => {
      if (link) {
        navigate(link);
      } else if (func) {
        func();
      }
    },
  };

  return (
    <span
      className={`text-sm md:text-lg font-bold leading-5 underline text-secondary decoration-secondary ${classes && classes}`}
      onClick={event.onClick}
    >
      {text}
      {children}
    </span>
  );
}

export default LinkText;
