import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './slothBallSection.css';

import SecondaryButton from "../../component/secondaryButton";
import SelectableButton from "../../component/selectableButton";
import LinkText from "../../component/linkText";
import DownloadSection from "../../component/downloadSection";

import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { toggleMintDialog } from "../../state/dialog";

import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { APTOS } from "../../utils/module_endpoints";
import { updateMinsStartDate } from "../../state/global";

interface ToBeAnnouncedProps {
  selectedButton?: number;
}

const SlothBallSection: React.FC<any> = () => {
  const [selectedButton, setSelectedButton] = useState(0);
  const [selectedSection, setSelectedSection] = useState(0);
  const [mintPrice, setMintPrice] = useState(5.4);
  const [mintDatem, setMintDate] = useState("19 August 4:00pm CET");

  const [timeUnits, setTimeUnits] = useState([
    { unit: "Days", value: "00" },
    { unit: "Hours", value: "00" },
    { unit: "Minutes", value: "00" },
    { unit: "Seconds", value: "00" }
  ]);

  const mintDate = useAppSelector((state) => state.globalState.mintStartTime) * 1000;
  useEffect(() => {
    const interval = setInterval(() => {
      const currentMintDate = new Date(mintDate);
      const now = new Date();
      const timeLeft = currentMintDate.getTime() - now.getTime();

      if (timeLeft < 0) {
        setTimeUnits([
          { unit: "Days", value: "00" },
          { unit: "Hours", value: "00" },
          { unit: "Minutes", value: "00" },
          { unit: "Seconds", value: "00" }
        ]);
        return;
      }

      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

      setTimeUnits([
        { unit: "Days", value: String(days).padStart(2, '0') },
        { unit: "Hours", value: String(hours).padStart(2, '0') },
        { unit: "Minutes", value: String(minutes).padStart(2, '0') },
        { unit: "Seconds", value: String(seconds).padStart(2, '0') }
      ]);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [mintDate]);


  const dispatch = useAppDispatch();
  const { account, connected } = useWallet();

  useEffect(() => {
    const fetchMintStartTime = async () => {
      if (connected) {
        const typeParam = selectedButton == 1 ? "0xca4118f383cfdea6132bd4017b01fcc21362dc2fd6181855cd1e737b3fc5af19::pre_mint::PublicInfo"
          : "0xca4118f383cfdea6132bd4017b01fcc21362dc2fd6181855cd1e737b3fc5af19::pre_mint::CoolListInfo"
        try {
          const res = await APTOS.view({
            payload: {
              function: `0xca4118f383cfdea6132bd4017b01fcc21362dc2fd6181855cd1e737b3fc5af19::pre_mint::mint_start_time`,
              typeArguments: [typeParam],
              functionArguments: []
            }
          })
          if (res) {
            console.log(res);
            dispatch(updateMinsStartDate(res[0]));
          }
        } catch (err) {
          console.error(err);
        }
      }
    };
    fetchMintStartTime();
  }, [account, connected, selectedButton])

  useEffect(() => {
    if (selectedButton === 0) {
      setMintPrice(5.4);
      setMintDate("19 August 2:00pm UTC")
    } else if (selectedButton === 1) {
      setMintPrice(6.9);
      setMintDate("23 August 2:00pm UTC")
    }
  }, [selectedButton]);

  const buttons = [
    { label: "COOL LIST" },
    { label: "PUBLIC" },
  ];

  const sections = [
    {
      title: "MINT SLOTHBALLS",
      description: () => (
        <span>
          <span>"Open “Minting” Tile mini-app on TowneSquare and mint your Slothball. Are you eligible for the CoolList? </span>
          <LinkText text="Check here" func={() => dispatch(toggleMintDialog(true))} />
        </span>
      ),
      bgColor: "bg-primary",
      textColor: "text-black"
    },
    {
      title: "DEFEAT EVIL SLOTH & EVOLVE YOUR SLOTHBALLS",
      description: () => (
        <span>Open “Battle & Evolve” Tile mini-app on TowneSquare and defeat the Evil Sloth together with other Slothball holders. Join the Evolving your Slothballs  into Cool Sloths after the battle! Cool right?</span>
      ),
      bgColor: "bg-purple-2",
      textColor: "text-white"
    },
    {
      title: "JOIN THE COOL SLOTH TOKEN - GATED COMMUNITY",
      description: () => (
        <span>Join the exclusive Cool Sloth token-gated community right in the TowneSquare app and celebrate with your our flow Sloths</span>
      ),
      bgColor: "bg-purple-3",
      textColor: "text-white"
    }
  ];

  const ToBeAnnounced = ({ selectedButton }: ToBeAnnouncedProps) => (
    <div className="flex flex-col text-sm gap-y-0.5 md:gap-y-2 md:text-xl bg-[#A0A0A0] rounded-2xl">

    </div>
  )

  const FirstTab = () => (
    <div className="flex flex-col items-center justify-between px-4 pt-12 md:pt-20 md:px-0">
      {/* top image */}
      <img src="/assets/home/bunch-aptos-anim.png" className="w-[209px] md:w-[480px] h-auto" />
      {/* buttons */}
      <div className="flex h-12 mt-6 mb-6 border border-black rounded-lg md:mt-20 shadow-default bg-primary">
        {buttons.map((button, index) => (
          <SelectableButton
            key={index}
            label={button.label}
            moreStyle="w-[120px]"
            isSelected={selectedButton === index}
            onClick={() => setSelectedButton(index)}
          />
        ))}
      </div>
      {/* bottom mint countdown */}
      <div className="flex w-full md:pl-8 gap-x-4 md:gap-x-8">
        {/* mint price */}
        <div className="flex flex-col pr-4 border-r md:pr-8 border-r-gray-dark">
          <p className="text-sm font-semibold md:text-lg">Mint Price</p>
          <p className="mt-8 text-lg font-semibold md:text-3xl">{mintPrice} APT</p>
        </div>
        <div className="flex flex-col space-y-4 w-fit">
          {/* mint date */}
          <div className="flex items-center gap-x-4">
            <div className="flex items-center">
              <p className="text-sm font-semibold md:text-lg leading-[130%] text-[#121212] mr-4">Mint date</p>
              <p className="text-sm font-normal md:text-base text-[#121212]">{mintDatem}</p>
            </div>
            {/* <p className={`text-xs font-normal md:text-sm ${['opacity-0', 'opacity-1'][selectedButton]}`}>20 April 00:00 GMT</p> */}
          </div>
          {/* <ToBeAnnounced /> */}
          {/* hide countdown for now */}
          <div className="flex w-full items-center h-10 md:h-[61px] bg-[#A0A0A0] text-white rounded-[10px]">
            {timeUnits.map((timeUnit, index) => (
              <div
                key={index}
                className="flex flex-col w-fit h-full justify-center border-r border-opacity-[10%]"
              >
                <p className="text-sm md:text-[20px] font-semibold text-center leading-[100%]">{timeUnit.value}</p>
                <p className="mx-3 text-xs font-normal text-center md:text-base">{timeUnit.unit}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full mt-6 gap-y-4 md:hidden">
        <span className="text-lg font-black text-center">{sections[0].title}</span>
        <span className="text-xs font-semibold">{sections[0].description()}</span>
      </div>
    </div>
  )

  const SecondTab = () => (
    <div className="flex flex-col items-center justify-end h-full px-6 pb-8 bg-no-repeat bg-contain pt-72 md:pt-0 md:bg-cover bg-purple-2 bg-ball-section-m md:bg-ball-section">
      <div className="flex w-full text-white md:pl-0 gap-x-4 md:gap-x-12">
        <div className="flex flex-col pr-4 -ml-4 border-r md:-ml-0 gap-y-4 md:gap-y-12 md:pr-8">
          <p className="text-sm font-semibold md:text-lg">Battle requirements</p>
          <p className="text-lg font-semibold md:text-3xl">SlothBall</p>
        </div>
        <div className="flex flex-col mb-4 md:-ml-5 w-fit gap-y-2 md:gap-y-6">
          <div className="flex items-center gap-4">
            <p className="text-sm font-semibold md:text-lg">Battle starts on</p>
            {/* <p className="text-xs font-normal md:text-sm">20 April 00:00 GMT</p> */}
          </div>
          {/* <ToBeAnnounced selectedButton={1} /> */}
          <div className="flex w-full items-center h-10 md:h-[61px] bg-[#A0A0A0] text-[white] rounded-[10px] mt-6">
            {timeUnits.map((timeUnit, index) => (
              <div
                key={index}
                className="flex flex-col w-fit h-full justify-center border-r border-opacity-[10%] last:border-none"
              >
                <p className="text-[20px] font-semibold text-center leading-[100%]">{timeUnit.value}</p>
                <p className="text-[16px] font-[400] mx-3 text-center">{timeUnit.unit}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full mt-4 text-white md:mt-6 gap-y-4 md:hidden">
        <span className="text-lg font-black text-center">{sections[1].title}</span>
        <span className="text-xs font-semibold">{sections[1].description()}</span>
      </div>
    </div>
  )

  const ThirdTab = () => (
    <div className='flex flex-col items-center px-6 pb-8 md:p-0'>
      <div className="flex flex-col items-center">
        <img src="/assets/home/bg-ball-3-m.png" className="w-[246px] md:hidden" />
        <img src="/assets/home/bg-ball-3.png" className="hidden md:block md:w-[360px]" />
        <div className="flex flex-col items-center mt-4 md:mt-8">
          <SecondaryButton className="w-[156px] h-11 opacity-30 !bg-primary !text-[black]">JOIN  COMMUNITY</SecondaryButton>
          <p className="text-[18px] text-gray-dark-1 mb-2 mt-4" >Coming Soon</p>
        </div>
      </div>
      <div className="flex flex-col w-full mt-2 md:mt-6 gap-y-4 md:hidden">
        <span className="text-lg font-black text-center">{sections[2].title}</span>
        <span className="text-xs font-semibold">{sections[2].description()}</span>
      </div>
    </div>
  )

  return (
    <div className="flex flex-col items-center w-full hi-fit text-gray-dark font-[700]">
      {/* top title */}
      <p className="text-center text-4xl md:text-[50px] leading-none font-extrabold mt-[85px]">Meet SlothBalls &<br /> Beat the Evil Sloth</p>
      {/* intro title */}
      <div className="mt-4 md:w-[850px] block text-center">
        <img src='/assets/home/ts-logo.svg' className='w-40 md:w-[223px] h-auto inline-block align-middle' />
        <span className="text-xl md:text-[28px] font-semibold md:leading-[42px]"> offers new ways of interacting with the Web3 through its mini-apps - Tiles.</span>
      </div>
      {/* download section */}
      <DownloadSection />
      {/* main content */}
      <div className="flex justify-center gap-8 mt-28">
        {/* left tabs */}
        <div className='flex-col hidden gap-4 md:flex'>
          {sections.map((section, index) => (
            <div
              key={index}
              className={`flex flex-col justify-center cursor-pointer py-8 px-6 gap-y-4 ${selectedSection == index ? `${section.bgColor} ${section.textColor}` : 'bg-[white] text-[black]'} w-[623px] h-[213px] rounded-[10px] border border-gray-dark shadow-default`}
              onClick={() => setSelectedSection(index)}
            >
              <p className='text-[28px] font-black leading-9'>{section.title}</p>
              {selectedSection === index && section.description()}
            </div>
          ))}
        </div>
        {/* contents on the right on desktop */}
        <div className={`hidden md:flex flex-col w-[625px] md:h-[673px] rounded-[20px] shadow-default-2 border border-gray-dark overflow-hidden ${['bg-primary', 'bg-purple-4', ''][selectedSection]}`}>
          {/* tab 1: mint */}
          {selectedSection === 0 && FirstTab()}
          {/* tab 2: battle */}
          {selectedSection === 1 && SecondTab()}
          {/* tab 3: community */}
          {selectedSection === 2 && ThirdTab()}
        </div>
        {/* contents on the right on mobile */}
        <div className="flex md:hidden w-screen px-1.5 box-border">
          <div className="w-full overflow-auto border rounded-xl shadow-default-2 border-gray-dark">
            <Swiper
              modules={[Pagination]}
              slidesPerView={1}
              pagination={{ clickable: true }}
            >
              <SwiperSlide>
                {FirstTab()}
              </SwiperSlide>
              <SwiperSlide>
                {SecondTab()}
              </SwiperSlide>
              <SwiperSlide>
                {ThirdTab()}
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlothBallSection;