import _ from "lodash";
import { useState } from "react";

const tabsInfo = [
  {
    label: "Body",
    svg: '/assets/compose/tab-body.svg',
    items: [
      'Ghost #1',
      'Panda #1',
      'OG #1',
    ],
    activeItem: -1,
  },
  {
    label: "Clothes",
    svg: '/assets/compose/tab-clothes.svg',
    items: [
      'Aligator suit #1',
      'Aptos 2024 Ecosystem #1',
      'Mingos feather boa #1',
    ],
    activeItem: -1,
  },
  {
    label: "Eyes",
    svg: '/assets/compose/tab-eyes.svg',
    items: [
      'Nouns glasses #1',
      'Apple Vision Pro #1',
      'Aptos glasses #1',
    ],
    activeItem: -1,
  },
  {
    label: "Mouth",
    svg: '/assets/compose/tab-mouth.svg',
    items: [
      'Bubble gum #1',
      'Santa beard #1',
      'Evil smile #1',
    ],
    activeItem: -1,
  },
  {
    label: "Hat",
    svg: '/assets/compose/tab-hat.svg',
    items: [
      'Crown #1',
      'Crown #1',
      'Crown #1',
    ],
    activeItem: -1,
  },
  {
    label: "Badges",
    svg: '/assets/compose/tab-badges.svg',
    items: [
      'Crown #1',
      'Crown #1',
      'Crown #1',
    ],
    activeItem: -1,
  },
  {
    label: "Bg",
    svg: '/assets/compose/tab-bg.svg',
    items: [
      'Blue #1',
      'Aptos #1',
      'Yellow #1',
    ],
    activeItem: -1,
  },
];

const ComposeSection: React.FC<any> = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [tabs, setTabs] = useState(tabsInfo);

  const navToSite = () => {
    window.open("https://townespace.xyz/", "_blank");
  };

  const activeNftItem = (tabIndex: number, itemIndex: number) => {
    const updatedTabs = _.cloneDeep(tabs);
    updatedTabs[tabIndex].activeItem = itemIndex;
    setTabs((_) => updatedTabs);
  }

  return (
    <div className="relative flex flex-col items-center w-full pt-64 md:pt-32 pb-56 md:pb-12 -mt-8 md:mt-2.5 text-black bg-gray-dark">
      {/* desktop bg */}
      <div className="hidden md:block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-content-3 h-[985px] z-10">
        <img className="w-full h-auto" src="/assets/home/bg-compose.png" alt="bg" />
      </div>
      {/* mobile bg */}
      <div className="absolute top-0 bottom-0 left-0 right-0 z-10 md:hidden">
        <img className="w-[147px] h-auto absolute top-36" src="/assets/home/bg-compose-1.png" alt="bg" />
        <img className="w-[118px] h-auto absolute top-36 right-0" src="/assets/home/bg-compose-2.png" alt="bg" />
        <img className="absolute h-auto w-25 bottom-24" src="/assets/home/bg-compose-3.png" alt="bg" />
        <img className="w-[150px] h-auto absolute bottom-24 right-0" src="/assets/home/bg-compose-4.png" alt="bg" />
      </div>
      {/* compose nft */}
      <div className="w-full md:w-content-3 md:h-[985px] flex flex-col items-center z-20">
        {/* title */}
        <span className="w-full px-12 text-4xl font-extrabold text-center text-white md:px-0 md:mt-40 md:text-5xl">Compose your Cool Sloths</span>
        {/* powered by */}
        <span className="flex items-center mt-6 gap-x-[10px]">
          <span className="text-lg font-bold text-white">powered by</span>
          <img
            src="/assets/home/ts-logo-2.png"
            className="w-auto h-[34px] cursor-pointer"
            alt="ts-logo"
            onClick={navToSite}
          />
        </span>
        <div className="flex flex-col items-center self-start w-full md:mt-12 md:w-auto md:space-x-20 md:flex-row">
          {/* NFT compose preview */}
          <div className="flex flex-col gap-y-8">
            {/* NFT preview */}
            <div className="relative w-70 md:w-100 h-70 md:h-100">
              {/* bg at the bottom */}
              {tabs[6].activeItem > -1 && (
                <img className="absolute top-0 left-0 w-full h-full" src={`/assets/compose/bg-${tabs[6].activeItem + 1}.png`} alt="bg" />
              )}
              {/* then body */}
              {tabs[0].activeItem > -1 ? (
                <img className="absolute top-0 left-0 w-full h-full" src={`/assets/compose/body-${tabs[0].activeItem + 1}.png`} alt="body" />
              ) : (
                <img className="absolute top-0 left-0 w-full h-full" src={`/assets/compose/body-3.png`} alt="body" />
              )}
              {/* then clothes */}
              {tabs[1].activeItem > -1 && (
                <img className="absolute top-0 left-0 w-full h-full" src={`/assets/compose/clothes-${tabs[1].activeItem + 1}.png`} alt="clothes" />
              )}
              {/* then mouth */}
              {tabs[3].activeItem > -1 && (
                <img className="absolute top-0 left-0 w-full h-full" src={`/assets/compose/mouth-${tabs[3].activeItem + 1}.png`} alt="mouth" />
              )}
              {/* then hat */}
              {tabs[4].activeItem > -1 && (
                <img className="absolute top-0 left-0 w-full h-full" src={`/assets/compose/hat-${tabs[4].activeItem + 1}.png`} alt="hat" />
              )}
              {/* then eyes */}
              {tabs[2].activeItem > -1 && (
                <img className="absolute top-0 left-0 w-full h-full" src={`/assets/compose/eyes-${tabs[2].activeItem + 1}.png`} alt="eyes" />
              )}
              {/* then badges */}
              {tabs[5].activeItem > -1 && (
                <img className="absolute top-0 left-0 w-full h-full" src={`/assets/compose/badges-${tabs[5].activeItem + 1}.png`} alt="badges" />
              )}
            </div>
            {/* description text */}
            <div className="flex flex-col gap-y-2">
              <span className="text-sm font-medium text-center md:text-2xl md:text-start text-gray-3">Cool Sloths</span>
              <span className="text-xl font-semibold text-center text-white md:text-3xl md:text-start">Cool Sloth #1</span>
            </div>
          </div>
          {/* NFT component selector */}
          <div className="flex flex-col items-center overflow-hidden gap-y-6 md:gap-y-12">
            {/* NFT component tab wrapper */}
            <div className="flex w-screen px-5 mt-6 overflow-x-scroll md:w-fit md:px-0 md:mt-0 md:overflow-auto">
              {/* NFT component tabs */}
              <div className="flex rounded-[10px] border border-primary p-0.5">
                {tabs.map((button, index) => (
                  <button
                    key={index}
                    className={`rounded-lg py-1 md:py-0.5 px-6 flex flex-col items-center gap-y-1 ${index === activeTab ? 'bg-primary' : 'bg-gray-dark'}`}
                    onClick={() => setActiveTab(index)}
                  >
                    <img className="w-6 h-6 md:w-8 md:h-8" src={`/assets/compose/tab-${button.label.toLowerCase()}${index === activeTab ? '-active' : ''}.svg`} alt="icon" />
                    <span className={`text-xs md:text-lg font-black ${index === activeTab ? 'text-gray-dark' : 'text-primary'}`}>{button.label.toUpperCase()}</span>
                  </button>
                ))}
              </div>
            </div>
            {/* NFT component list */}
            <div className="w-screen px-5 overflow-x-scroll md:px-0 md:overflow-auto md:w-fit">
              <div className="grid justify-between grid-cols-3 md:flex md:justify-start gap-x-4 md:gap-x-14">
                {Array.from({ length: 3 }).map((_, index2) => (
                  <div
                    key={index2}
                    className="flex flex-col cursor-pointer gap-y-1.5 w-full md:w-compose md:gap-y-3"
                    onClick={() => activeNftItem(activeTab, index2)}
                  >
                    <div className={`rounded-lg w-full pb-[100%] relative md:w-compose md:h-compose bg-gray-4 border-4 ${tabs[activeTab].activeItem === index2 ? 'border-purple-1' : 'border-transparent'}`}>
                      <img className="absolute w-full h-full" src={`/assets/compose/${tabs[activeTab].label.toLowerCase()}-${index2 + 1}.png`} alt="nft-component" />
                    </div>
                    <div className="flex flex-col w-full">
                      <span className="text-xs font-semibold md:text-xl text-gray-3">{tabs[activeTab].label}</span>
                      <span className="text-sm md:text-[21px] font-semibold text-white leading-6 md:h-12">{tabs[activeTab].items[index2]}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComposeSection;
