import { useState, useEffect } from "react";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import WalletButtons from "../../wallet-adapter/WalletButtons";
import LinkText from "../../component/linkText";

import { useAppSelector, useAppDispatch } from "../../state/hooks";
import { toggleMintDialog, toggleWalletPanel } from "../../state/dialog";
import { isCoolEligible } from "../../state/global";

const NFTItems = [
  {
    src: '/assets/mint-dialog/nft-1.png',
    name: 'Aptos Monkeys',
    note: '(including staked)',
  },
  {
    src: '/assets/mint-dialog/nft-2.png',
    name: 'Aptomingos',
    note: '(including staked)',
  },
  {
    src: '/assets/mint-dialog/nft-3.png',
    name: 'Mavrik',
    note: '(including staked)',
  },
  {
    src: '/assets/mint-dialog/nft-4.png',
    name: 'Creatus',
  },
  {
    src: '/assets/mint-dialog/nft-5.png',
    name: 'Proud Lions Club',
  },
  {
    src: '/assets/mint-dialog/nft-6.png',
    name: 'Bruh Bears',
  },
  {
    src: '/assets/mint-dialog/nft-7.png',
    name: 'Ice Blue',
  },
  {
    src: '/assets/mint-dialog/nft-8.png',
    name: 'AptoRobos',
  },
  {
    src: '/assets/mint-dialog/nft-9.png',
    name: 'Pontem Space Pirates',
  },
  {
    src: '/assets/mint-dialog/nft-10.png',
    name: 'Zaaptos',
  },
  {
    src: '/assets/mint-dialog/nft-11.png',
    name: 'Renegades',
  },
  {
    src: '/assets/mint-dialog/nft-12.png',
    name: 'The Loonies',
  },
  {
    src: '/assets/mint-dialog/nft-13.png',
    name: 'Ice Dogs',
  },
  {
    src: '/assets/mint-dialog/nft-14.png',
    name: 'Spooks',
  },
];

const Tokens = [
  {
    src: '/assets/mint-dialog/token-1.png',
    name: 'GUI INU',
    note: 'Holding ≥ $10mill $GUI',
  },
  {
    src: '/assets/mint-dialog/token-2.png',
    name: 'SEEDZ',
    note: 'Any amount',
  },
];

const DeFis = [
  {
    src: '/assets/mint-dialog/defi-1.png',
    name: 'Joule Finance',
    note: 'Provide APT liquidity in deposit pool',
  },
];

const MintDialog: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const showDialog = useAppSelector((state) => state.dialogState.bMintDialog);
  const isEligible = useAppSelector((state) => state.globalState.isEligible);
  const eligibleData = useAppSelector((state) => state.globalState.EligibleData);
  const { connected, account, disconnect } = useWallet();
  const [showRequirements, setShowRequirements] = useState<boolean>(false);
  const [mintDialogTitle, setMintDialogTitle] = useState<string>("CoolList Eligibility");
  const [eligibilityImage, setEligibilityImage] = useState<string>("/assets/mint-dialog/to-mint.png");
  const [eligibilityStyle, setEligibilityStyle] = useState<string>("hidden");
  const [eligibilityText, setEligibilityText] = useState<string>("");

  useEffect(() => {
    if (isEligible === null) return;

    updateEligibilityImage();
    updateEligibilityStyle();
    updateEligibilityText();
  }, [isEligible]);

  useEffect(() => {
    changeDialogTitle(showRequirements);
  }, [showRequirements]);

  useEffect(() => {
    if (connected && account) {
      dispatch(isCoolEligible(account?.address));
      dispatch(toggleWalletPanel(false));
    }
  }, [connected, account]);

  const changeDialogTitle = (showRequirements: boolean) => {
    if (!showRequirements) {
      setMintDialogTitle("CoolList Eligibility");
    } else {
      setMintDialogTitle("CoolList Requirements");
    }
  };

  const updateEligibilityImage = () => {
    if (!connected) {
      setEligibilityImage("/assets/mint-dialog/to-mint.png");
    } else if (isEligible) {
      setEligibilityImage("/assets/mint-dialog/can-mint.png");
    } else if (!isEligible) {
      setEligibilityImage("/assets/mint-dialog/cant-mint.png");
    }
  };

  const updateEligibilityStyle = () => {
    if (!connected) {
      setEligibilityStyle("hidden");
    } else if (isEligible) {
      setEligibilityStyle("bg-secondary");
    } else if (!isEligible) {
      setEligibilityStyle("bg-tertiary");
    }
  };

  const updateEligibilityText = () => {
    if (isEligible) {
      setEligibilityText("F YES! YOU ARE IN! GO CELEBRATE!");
    } else {
      setEligibilityText("BONKERS! YOU ARE NOT ELIGIBLE!");
    }
  };

  const closeDialog = () => {
    dispatch(toggleMintDialog(false));
  };

  if (showDialog) return (
    // transparent full screen mask
    <div className="fixed top-0 left-0 z-[999] pt-9 md:pt-0 flex items-center justify-center w-screen h-screen bg-gray-dark md:bg-black/80">
      {/* dialog */}
      <div className="w-full md:w-[625px] h-full md:h-[584px] bg-primary rounded-t-[10px] md:rounded-[20px] overflow-hidden flex flex-col">
        {/* dialog header */}
        <div className="flex items-center justify-between h-16 px-6 py-3 bg-white">
          {/* show back button when showing requirements */}
          {!showRequirements ? (
            <img className="w-6 h-6 opacity-0 md:w-8 md:h-8" src="/assets/back.svg" alt="close" />
          ) : (
            <img
              className="w-6 h-6 cursor-pointer md:w-8 md:h-8"
              src="/assets/back.svg"
              alt="close"
              onClick={() => setShowRequirements(false)}
            />
          )}
          {/* dialog title */}
          <span className="text-lg md:text-[22px] font-[900] text-gray-dark">{mintDialogTitle}</span>
          {/* close dialog */}
          <img
            className="w-6 h-6 cursor-pointer md:w-8 md:h-8"
            src="/assets/close.svg"
            alt="close"
            onClick={closeDialog}
          />
        </div>
        {/* dialog body to check coolList eligibility */}
        {!showRequirements && (
          <div className="flex flex-col items-center justify-between px-8 py-8 overflow-y-auto md:justify-start md:px-0 md:pt-8 grow">
            {/* image showing current status */}
            <img className="h-[200px] w-[208px]" src={eligibilityImage} alt="to-mint" />
            {/* prompt text */}
            <div className="flex flex-col items-center">
              <span className="mt-8 font-bold leading-5 text-center text-gray-dark">Are you eligible for the CoolList? Only one way to find out</span>
              {/* see requirements */}
              {!showRequirements && (
                <LinkText
                  text="See CoolList requirements"
                  classes="!text-base mt-2 cursor-pointer"
                  func={() => setShowRequirements(true)}
                />
              )}
              {/* wallet address and connect/disconnect button */}
              {connected && (
                <div className="flex items-center justify-center gap-4 mt-4">
                  <p className="text-base font-normal leading-normal text-black">{account?.address.slice(0, 13)}...{account?.address.slice(-13)}</p>
                  <button
                    onClick={() => { disconnect() }}
                    className="px-4 text-lg font-black rounded-lg h-11 bg-gray-dark text-primary disabled:cursor-not-allowed">
                    DISCONNECT
                  </button>
                </div>)}
            </div>
            {!connected && (<button
              className="w-[309px] mt-4 h-11 bg-gray-dark rounded-lg text-primary text-lg font-black disabled:cursor-not-allowed"
              onClick={() => { dispatch(toggleWalletPanel(true)); }}
            >
              CONNECT YOUR WALLET
            </button>)}
            {/* mint check result */}
            <div className={`mt-6 w-[500px] h-[52px] rounded-[10px] flex justify-center items-center text-lg text-white font-extrabold py-4 ${eligibilityStyle}`}>
              <span>{eligibilityText}</span>
            </div>
            {/* show this when eligible, hide when not but keep the size */}
            <div className={`mt-4 mb-8 text-xl font-bold select-none text-gray-dark ${!isEligible && 'opacity-0'}`}>Your CoolList allocations: {eligibleData?.maxMintNumber}</div>
          </div>
        )}
        {/* dialog body of requirements */}
        {showRequirements && (
          <div className="flex flex-col items-center p-4 overflow-y-auto md:p-8 gap-y-8 text-gray-dark">
            <div className="font-semibold leading-5">You will be eligible for the CoolList if you meet at least 1 of the following requirements. The more requirements you meet, the more slots you will get.</div>
            <div className="flex flex-col md:flex-row items-start md:items-center w-full gap-x-1.5">
              <span className="font-bold">1. Be in the top 6000 ranked users on </span>
              <img className="w-auto h-7" src="/assets/logo-cred-2.png" alt="logo" />
            </div>
            {/* NFTs */}
            <div className="flex flex-col gap-y-6">
              <div className="w-full font-bold">2. Holding at least 1 NFT. Each NFT gives 1 CoolList allocation</div>
              <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
                {NFTItems.map((item, index) => (
                  <div className="flex items-center gap-x-2" key={index}>
                    <img src={item.src} className="w-7 h-7" alt="nft" />
                    <div className="flex flex-col text-sm">
                      <span className="font-bold">{item.name}</span>
                      {item?.note && <span className="">{item.note}</span>}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* tokens */}
            <div className="flex flex-col w-full gap-y-6">
              <div className="w-full font-bold">3. Hold tokens</div>
              <div className="grid grid-cols-2 gap-4">
                {Tokens.map((item, index) => (
                  <div className="flex items-center gap-x-2" key={index}>
                    <img src={item.src} className="w-7 h-7" alt="token" />
                    <div className="flex flex-col text-sm">
                      <span className="font-bold">{item.name}</span>
                      {item?.note && <span className="">{item.note}</span>}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* DeFi */}
            <div className="flex flex-col w-full gap-y-6">
              <div className="w-full font-bold">4. DeFi</div>
              <div className="grid grid-cols-2 gap-4">
                {DeFis.map((item, index) => (
                  <div className="flex items-center gap-x-2" key={index}>
                    <img src={item.src} className="w-7 h-7" alt="token" />
                    <div className="flex flex-col text-sm">
                      <span className="font-bold">{item.name}</span>
                      {item?.note && <span className="">{item.note}</span>}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
  else return <></>;
};

export default MintDialog;
