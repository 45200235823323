import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

interface eligible {
  id: string;
  wallet: string;
  maxMintNumber: number;
  isGuaranteed: boolean;
  createdAt: string;
};

interface globalStates {
  step: number;
  initialized: boolean;
  visitorMode: boolean;
  isEligible: boolean | null;
  EligibleData: eligible;
  mintStartTime: number;
}

const initialState: globalStates = {
  step: 0,
  initialized: false,
  visitorMode: false,
  isEligible: null,
  mintStartTime: 0,
  EligibleData: {} as eligible,
};

export const isCoolEligible = createAsyncThunk(
  "isEligible",
  async (wallet: string, thunkAPI) => {

    const url = `${process.env.REACT_APP_BACKEND}/activity/isCoolEligible/${wallet}`;
    try {
      const res = await fetch(url);
      const result = await res.json();
      return result;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    resetGlobal: (state, action: PayloadAction<boolean>) => {
      state.step = 0;
      state.initialized = false;
      state.visitorMode = false;
    },
    updateStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    updateInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
    updateVisitorMode: (state, action: PayloadAction<boolean>) => {
      state.visitorMode = action.payload;
    },
    updateMinsStartDate: (state, action: PayloadAction<any>) => {
      state.mintStartTime = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(isCoolEligible.fulfilled, (state, action) => {
      if (!action.payload.statusCode) {
        state.isEligible = action.payload.isEligible;
        if (action.payload.data) {
          state.EligibleData = action.payload.data;
        }
      }
    })
  }
});

export const { resetGlobal, updateStep, updateInitialized, updateVisitorMode, updateMinsStartDate } = globalSlice.actions;
export default globalSlice.reducer;
