import React from 'react';

interface SelectableButtonProps {
  label: string;
  moreStyle: string;
  isSelected: boolean;
  onClick: () => void;
}

const SelectableButton: React.FC<SelectableButtonProps> = ({ label, moreStyle, isSelected, onClick }) => {
  const baseStyle = 'flex items-center justify-center border-primary rounded-lg border text-sm md:text-lg font-black';
  const selectedStyle = 'bg-black text-white';
  const notSelectedStyle = 'text-black';

  return (
    <button
      onClick={onClick}
      className={`z-[100] ${moreStyle} ${baseStyle} ${isSelected ? selectedStyle : notSelectedStyle}`}
    >
      {label}
    </button>
  );
};

export default SelectableButton;
