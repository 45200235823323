import {
  useEffect,
  useState,
} from "react";
import NftSlider from "../../component/nftSlider";

const MainSection: React.FC<any> = () => {
  const [numberOfImages, setNumberOfImages] = useState(0);

  useEffect(() => {
    const updateImageCount = () => {
      const imageWidth = 250;
      const containerElement = document.querySelector('.image-container') as HTMLElement;
      const containerWidth = containerElement?.offsetWidth || 1000;
      const imagesToFit = Math.ceil(containerWidth / imageWidth);
      setNumberOfImages(imagesToFit);
    };

    updateImageCount();
    window.addEventListener('resize', updateImageCount);

    return () => window.removeEventListener('resize', updateImageCount);
  }, []);

  return (
    <>
      <div className='flex flex-col items-center md:pb-[106px]'>
        <div
          className="relative z-20 flex flex-col items-center w-full pb-12 mt-8 md:mt-16 md:h-fit md:pb-44"
          style={{
            backgroundImage: `url(/assets/home/bg.png)`,
            backgroundPosition: 'center',
            backgroundSize: '100% 100%',
          }}
        >
          {/* hero image shown in mobile */}
          <img src='/assets/logo2.png' className='w-[275px] h-auto block md:hidden' />
          <img src='/assets/home/mobileItem.png' className='block md:hidden z-[100]' />
          {/* hero image shown in desktop */}
          <div className='flex-col hidden pb-10 md:flex'>
            <img src='/assets/home/mainItem.png' className='flex xl:h-[445px] xl:w-[1294px]' />
            <div className='flex justify-center gap-2'>
              <p className='text-[18px] text-gray-dark font-bold'>by</p>
              <img src='/assets/home/ts-logo.svg' className='w-[171px] h-8' />
            </div>
          </div>
          <div className='absolute -bottom-10 w-full lg:w-[934px]'>
            <p className='text-center text-[38px] md:text-[70px] text-[#000] font-[900] leading-[110%]'>
              Built with <span className='text-[#8A69F0]'>composability</span><br />& <span className='text-secondary'>for mobile</span>
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full mt-20 md:mt-0">
        <a href={"https://battle.coolsloths.com/"} rel="noreferrer" target="_blank" className="flex items-center w-[300px] text-center px-3 py-2 bg-[#6BCDCB] border rounded-[10px] justify-center h-full border-gray-dark shadow-default">
          <p className="cursor-pointer text-[#FFF] text-lg font-bold leading-normal uppercase">Evolve sloth balls!</p>
        </a>
      </div>
      <div
        className='flex items-center w-full pt-8 mt-10 md:pt-4 image-container'
        style={{
          backgroundImage: `url(/assets/home/bg-foot.png)`,
          backgroundPosition: 'center',
          backgroundSize: '100% 100%',
          overflowX: 'visible',
          overflowY: 'hidden'
        }}>
        <NftSlider numberOfImages={numberOfImages} />
      </div>
    </>
  );
};

export default MainSection;
