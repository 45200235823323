const JoinUs = () => {
  return (
    <div className="flex items-center gap-8 md:gap-2">
      <a
        href="https://discord.gg/yMRmqFzrDW"
        target="_blank"
        className="w-12 h-11"
      >
        <div className="flex items-center justify-center w-full h-full bg-white border rounded border-gray-dark shadow-default">
          <img src="/assets/header/discord.svg" alt="discord" className="w-[17px] md:w-6" />
        </div>
      </a>
      <a
        href="https://twitter.com/CoolSlothsxyz"
        target="_blank"
        className="w-12 h-11"
      >
        <div className="flex items-center justify-center w-full h-full bg-white border rounded border-gray-dark shadow-default">
          <img src="/assets/header/twitter.svg" alt="X" className="w-[18px] md:w-6" />
        </div>
      </a>
    </div>
  );
};

export default JoinUs;
