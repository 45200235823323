import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface dialogStates {
  bSidebar: number;
  bWalletHold: boolean;
  bWalletPanel: boolean;
  bMintDialog: boolean;
}

const initialState: dialogStates = {
  bSidebar: 0,
  bWalletPanel: false,
  bWalletHold: false,
  bMintDialog: false,
};

export const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    toggleSidebar: (state, action: PayloadAction<number>) => {
      console.log(action.payload)
      state.bSidebar = action.payload;
    },
    toggleWalletPanel: (state, action: PayloadAction<boolean>) => {
      state.bWalletPanel = action.payload;
    },
    toggleConnectRequest: (state, action: PayloadAction<boolean>) => {
      state.bWalletHold = action.payload;
    },
    toggleMintDialog: (state, action: PayloadAction<boolean>) => {
      state.bMintDialog = action.payload;
    }
  },
  extraReducers: (builder) => { },
});

export const {
  toggleSidebar,
  toggleWalletPanel,
  toggleConnectRequest,
  toggleMintDialog,
} = dialogSlice.actions;
export default dialogSlice.reducer;
