interface SlidebarProps {
  withBorder?: boolean;
}

const Slidebar = ({ withBorder }: SlidebarProps) => {
  return (
    <div className={`w-full py-4 overflow-hidden md:py-24 bg-gray-dark ${withBorder && 'border-y-white border-y-4'}`}>
      <div
        className='w-[11570px] h-8 md:h-12 animate-slideLtoR'
        style={{
          backgroundImage: 'url(/assets/home/slide.png)',
          backgroundSize: 'contain',
          backgroundRepeat: 'repeat-x',
          backgroundPosition: '0 0',
        }}
      >
      </div>
    </div>
  );
};

export default Slidebar;
