import React from "react";
import { Icon } from "@iconify/react";
import DownloadSection from "./downloadSection";
import JoinUs from "./header/joinus";

import { useAppSelector, useAppDispatch } from "../state/hooks";
import { toggleSidebar, toggleMintDialog } from "../state/dialog";

const MobileMenu: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const showSidebar = useAppSelector((state) => state.dialogState.bSidebar);

  const hideMobileMenu = () => {
    dispatch(toggleSidebar(0));
  };

  const showMintDialog = () => {
    dispatch(toggleSidebar(0));
    dispatch(toggleMintDialog(true));
  };

  if (showSidebar) return (
    <div className="fixed top-0 left-0 z-40 flex flex-col items-center justify-between w-screen h-screen pt-6 bg-white px-7 pb-11">
      {/* upper header */}
      <div className="flex items-center justify-between w-full">
        <Icon
          icon={"material-symbols:close"}
          className="w-8 h-8 text-black"
          onClick={hideMobileMenu}
        />
        <img src="/assets/logo.svg" className="w-auto h-11" alt="logo" />
        <Icon
          icon={"material-symbols:close"}
          className="w-8 h-8 text-black opacity-0"
        />
      </div>
      {/* lower content */}
      <div className="flex flex-col items-center w-full gap-4 pr-6">
        <a href={"https://battle.coolsloths.com/"} rel="noreferrer" target="_blank" className="flex items-center w-full px-3 py-3 bg-[#6BCDCB] border rounded justify-center h-full border-gray-dark shadow-default">
          <p  className="text-center cursor-pointer text-[#222] text-lg font-bold leading-normal uppercase">Evolve sloth balls!</p>
        </a>
        <div onClick={showMintDialog} className="flex items-center justify-center w-full h-full px-3 py-3 text-center bg-white border rounded border-gray-dark shadow-default">
          <p className="cursor-pointer text-[#222] text-lg text-center font-bold leading-normal uppercase" >CoolList eligibility</p>
        </div>
        {/* <DownloadSection /> */}
      </div>
      <JoinUs />
    </div>
  );

  return <></>;
};

export default MobileMenu;
