import { useState } from "react";
import SelectableButton from "../../component/selectableButton";
import DownloadSection from "../../component/downloadSection";

const SecondSection: React.FC<any> = () => {
  const [selectedButton, setSelectedButton] = useState(0);

  const buttons = [
    { label: 'MINT', style: 'w-1/4 md:w-[120px]' },
    { label: 'USE', style: 'w-1/4 md:w-[120px]' },
    { label: 'GET REWARDED', style: 'w-1/2 md:w-[171px]' },
  ];

  const texts = [
    'Participate in the first-ever mint on a mobile app! TowneSquare’s Mini Apps (Tiles) allows you to direct mint the NFT within the app in a gamified experience. Start your mini-app journey today!',
    'Use the Cool Sloths on TownSquare app and access exclusive token-gated communities and more!',
    'Sponsored gas-fees for Cool Sloths holders on TowneSquare app. More rewards and utilities coming soon!',
  ]

  return (
    <div className='flex flex-col items-center justify-between px-1.5 md:px-0 pt-20 text-gray-dark-1 h-fit'>
      <div className='flex flex-col items-center'>
        {/* slogon */}
        <div className='text-[50px] text-center font-extrabold leading-[42px] md:leading-none'>The 1st NFT collection</div>
        <div className='justify-center px-8 mt-4 text-xl font-semibold text-center gap-x-3 md:flex md:text-3xl'>
          <span>Built for the</span>
          <img src='/assets/home/ts-logo.svg' className='inline-block align-middle w-40 h-auto md:w-[223px]' />
          <span>mobile app & powered by AIP-76</span>
        </div>
        {/* download area */}
        <DownloadSection />
      </div>
      {/* tabs */}
      {/* tab-wrapper */}
      <div className='relative flex flex-col px-4 md:px-12 pt-10 border md:flex-row border-gray-dark mt-36 bg-gray-2 w-full mx-1.5 md:w-content md:h-content-2 shadow-default-2 rounded-2xl gap-x-32'>
        {/* hat at the top-left */}
        <img className="absolute h-auto -left-10 -top-[94px] w-52" src="/assets/home/hat.png" alt="hat" />
        {/* contents */}
        <div className="pt-6">
          {/* title */}
          <div className='text-center md:text-left text-[32px] font-black'>COOL SLOTHS ON MOBILE!</div>
          {/* mock only shown on mobile */}
          <div className="mt-10 md:hidden px-14">
            <img className="w-full h-auto" src={`/assets/home/phone${selectedButton + 1}-m.png`} alt="phone-mock" />
          </div>
          {/* clickable buttons */}
          <span className='flex w-11/12 h-12 mx-auto mt-12 mb-6 border border-black rounded-lg md:mx-0 md:w-fit shadow-default bg-primary'>
            {buttons.map((button, index) => (
              <SelectableButton
                key={index}
                label={button.label}
                moreStyle={`${button.style}`}
                isSelected={selectedButton === index}
                onClick={() => setSelectedButton(index)}
              />
            ))}
          </span>
          {/* intro text */}
          <div className='pb-8 md:pb-0 h-44 text-xl w-full md:w-[520px]'>
            {texts[selectedButton]}
          </div>
        </div>
        {/* mock only shown on desktop */}
        <div className="hidden mt-10 md:block md:mt-0">
          <img className="w-[236px] h-auto mx-auto md:mx-0" src={`/assets/home/phone${selectedButton + 1}.png`} alt="phone-mock" />
        </div>
      </div>
    </div>
  );
};

export default SecondSection;
